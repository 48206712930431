import { Link } from 'gatsby'
import * as React from 'react'
import { useServices } from '../hooks/useServices'
import getServiceRoute from '../lib/routing/getServiceRoute'
import Layout from './Layout'

const CityTemplate = ({ pageContext }) => {
  console.log(pageContext)
  const name = pageContext?.frontmatter?.name || ''
  const youtubeId = pageContext?.frontmatter?.youtubeId || ''
  const description = pageContext?.frontmatter?.description || ''
  const services = useServices()

  return (
    <Layout title={name} description={description}>
      <section className="py-8 md:py-16">
        <div className="container">
          <header className="mb-8">
            <h2>{name} Foundation Repair</h2>
            <p>We offer the following services in {name}, Wisconsin:</p>
          </header>
          <div className="flex flex-wrap -mx-4 md:-mx-8">
            {services.map((service) => (
              <div className="w-full px-4 mb-4 md:w-1/2 md:px-8">
                <h3>{service.node.frontmatter.name}</h3>
                <div>{service.node.frontmatter.shortDescription}</div>
                <Link
                  className="underline text-primary-dark"
                  to={getServiceRoute(service.node.frontmatter.name)}
                >
                  Learn more
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default React.memo(CityTemplate)
